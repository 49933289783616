import * as React from 'react';
import {FormEvent, useState} from 'react';
import {NavigateFunction, useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import Notification, {NotificationType} from "../../common/Notification";
import {
  currentApiDomain,
  EMPTY_STRING,
  getServiceNameFromLocation,
  isAllowedAsInput,
  isEmptyString, REQUEST_INIT
} from "../../../util/helperUtil";
import {
  CreateSiteAdminResponse, Exception, GetAssociateDetailsResponse,
  INTERNAL_SERVER_ERROR_CODE_ONE,
  RECOGNIZED_RESPONSE_STATUS,
  SessionDetails
} from "../../../module/AuthAdminModule";
import NfcTapView from "../../common/NfcTapView";
import {Form, FormField, Input, SpaceBetween} from '@amzn/awsui-components-react';
import ContentHeader from '../../common/ContentHeader';
import FormActions from '../../common/FormActions';
import {useTranslator} from '../../../localization/Translator';
import {Trans} from 'react-i18next';
import ErrorNotification from '../../common/ErrorNotification';
import ShowStatus from './ShowStatus';

const ReRegister = () => {
  const t = useTranslator();
  const navigate: NavigateFunction = useNavigate();

  /****  Constants  *****/
  const sessionDetails: SessionDetails = useOutletContext();

  /****  States   *****/
  const location = useLocation();
  const serviceName = getServiceNameFromLocation(location);

  const [inputValidationError, setInputValidationError] =
    React.useState(EMPTY_STRING);
  const [state, setState] = useState<GetAssociateDetailsResponse | null>(null);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [notification, setNotification] = useState<any>({
    type: NotificationType.INFO, header: "card_reader_check_header", message: "card_reader_check_description"
  } || null);
  const [cardUid, setCardUid] = useState("");
  const [showNFCTapView, setShowNFCTapView] = useState(false);
  const [alias, setAlias] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isInputInvalid, setIsInputInvalid] = React.useState(true);
  const [showAssociate, setShowAssociate] = React.useState(false);
  const [showBadgeDetails, setShowBadgeDetails] = React.useState(false);

  const ASSOCIATE_DETAILS_URL: string = `${currentApiDomain}/AssociateDetails?`;
  // const ASSOCIATE_DETAILS_URL: string = `http://localhost:5000/AssociateDetails?`;
  const OPERATIONS_URL: string = "/services/name_maask/operations";
  const ALIAS_MAX_LENGTH = 10;
  const ALIAS_MIN_LENGTH = 3;

  const isValidAlias = (alias: string) => {
    if (alias.length > ALIAS_MAX_LENGTH || alias.length < ALIAS_MIN_LENGTH) {
      setInputValidationError(t.translate("error_message_site_id_length"));
      return false;
    }
    if (isEmptyString(alias)) {
      setInputValidationError(t.translate("error_message_required_field"));
      return false;
    }
    if (!isAllowedAsInput(alias)) {
      setInputValidationError(t.translate("input_form_invalid_character_error_message"));
      return false;
    }
    return true;
  }
  const onChangeFn = (value: string) => {
    setInputValidationError(EMPTY_STRING);
    setIsInputInvalid(!isValidAlias(value));
    setAlias(value);
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isValidAlias(alias)) return;
    setIsLoading(true);
    fetch(ASSOCIATE_DETAILS_URL + new URLSearchParams({
      userId: alias, serviceName: serviceName
    }), {method: "GET", ...REQUEST_INIT})
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status === 401) {
          // redirect to authenticate
          window.location.replace(currentApiDomain + "/Authorize");
          return res.json();
        }
        if (RECOGNIZED_RESPONSE_STATUS.includes(res.status)) {
          return res.json();
        }
        throw new Error("Unrecognized response status " + JSON.stringify(res));
      })
      .then((res: any) => {
        // Success:200
        console.log(res);
        if (res.hasOwnProperty("responseCode")) {
          if (res.responseCode === 200 || res.responseCode === "200") {
            const responseMessage: GetAssociateDetailsResponse = res;
            if (!responseMessage.success) {
              // should not happen, backend should return with errorId instead of responseCode
              console.error("Unsuccessful response", res);
              setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
              return;
            }
            setState(responseMessage);
            return;
          }
          // should not happen, backend should return with errorId
          console.error("Response code other than 200", res);
          setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
          return;
        }
        // Exception from backend
        else if (res.hasOwnProperty("errorId")) {
          res = res as Exception;
          console.error("Unsuccessful API call", res.message, res);
          setErrorCode(res.errorId);
          return;
        } else {
          // should not happen
          console.error("Unknown response", res);
          setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
        console.error(err.message);
      });
  };

  const locationDescription = (
    <Trans>{t.translate("location_form_field_description")}</Trans>
  );

  if (state) {
    return (
      <div style={{width: "60%", margin: "0 auto"}}>
        {!state && !errorCode && (
          <div style={{width: "70%", margin: "0 auto"}}>
            <Notification notificationType={notification.type}
                          headerI18nKey={notification.header}
                          messageI18nKey={notification.message}/>
          </div>
        )}
        {errorCode &&
            <div style={{width: "70%", margin: "0 auto"}}>
                <ErrorNotification errorCode={errorCode} data={cardUid}/>
            </div>
        }
        {state && <ShowStatus data={state}/>}
      </div>
    );
  }

  return (
    <SpaceBetween size={"xxl"} alignItems={"center"}>
      <ContentHeader title={"Search AA with Alias"}/>
      <form className={"margin-top-20"}>
        <Form
          className={"width-flex"}
          actions={
            <FormActions
              cancelButtonName={t.translate("cancel_button_name")}
              submitButtonName={"Search with Alias"}
              cancelOnClick={() => navigate(OPERATIONS_URL)}
              isLoading={isLoading}
              isInputInvalid={isInputInvalid}
              submitOnClick={handleSubmit}
            />
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField
              stretch
              errorText={inputValidationError}
              description={locationDescription}
              label={"Please provide AA Alias"}>
              <Input value={alias}
                     onChange={(event) => onChangeFn(event.detail.value)}/>
            </FormField>
          </SpaceBetween>
        </Form>
      </form>
    </SpaceBetween>
  );
}

export default ReRegister;