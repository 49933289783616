import * as React from 'react';
import {EventHandler, useEffect, useState} from 'react';
import {
  Exception,
  HOME_PAGE_URL,
  INTERNAL_SERVER_ERROR_CODE_ONE,
  RECOGNIZED_RESPONSE_STATUS,
  ReRegisterAssociateResponse,
  SessionDetails
} from "../../../module/AuthAdminModule";
import {Navigate, NavigateFunction, useLocation, useOutletContext} from "react-router-dom";
import {currentApiDomain, getServiceNameFromLocation, HEADER_INIT, REQUEST_INIT} from "../../../util/helperUtil";
import Notification, {NotificationType} from "../../common/Notification";
import NfcTapView from "../../common/NfcTapView";
import ErrorNotification from "../../common/ErrorNotification";
import ResponsePage, {ResponsePageProps, ResponsePageType} from '../../common/ResponsePage';
import {useTranslator} from '../../../localization/Translator';
import {useNavigate} from 'react-router';

interface ReRegisterAssociate {
  userId: string;
}

const ReRegisterAssociate = (props: ReRegisterAssociate) => {
  const t = useTranslator();
  const navigate: NavigateFunction = useNavigate();
  /****  Constants  *****/
  const EMPTY_STRING: string = "";
  let readerCheckCount = 1;
  const sessionDetails: SessionDetails = useOutletContext();
  const READER_CHECK_LIMIT = 7;
  const READER_RETRY_IN_MILLISECONDS = 5000;
  const OTP_DEFAULT_LENGTH = 67;
  const RE_REGISTER_ASSOCIATE = `${currentApiDomain}/ReRegisterAssociate`;

  /****  States   *****/
  const location = useLocation();
  const serviceName = getServiceNameFromLocation(location);

  const [state, setState] = useState<ReRegisterAssociateResponse | null>(null);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [notification, setNotification] = useState({
    type: NotificationType.INFO, header: "card_reader_check_header", message: "card_reader_check_description"
  });
  const [response, setResponse] = React.useState<ResponsePageProps | null>(null);
  // const [responseMessage, setResponseMessage] = React.useState(EMPTY_STRING);
  // const [responseHeaderTitle, setResponseHeaderTitle] = React.useState(EMPTY_STRING);
  const [cardUid, setCardUid] = useState("");
  const [isBadgeScanned, setIsBadgeScanned] = useState(false);
  const [showNFCTapView, setShowNFCTapView] = useState(false);
  // const [responsePageOnClick, setResponsePageOnClick] = useState<EventHandler<any>>(() => {
  // });
  // const [responsePageType, setResponsePageType] = React.useState(
  //   ResponsePageType.INFORMATION
  // );

  const navigateToResetPin = () => {
    navigate("../pinReset");
  }


  /****  Methods   *****/
  const onDismissModal = () => {
    if (isBadgeScanned) {
      setShowNFCTapView(false);
    }
  }

  // handling /associateDetails API call
  const handleToken = async (tokenValue: string) => {
    // Parse out "urn:" that is included from Velocity NDEF reads
    console.log("1.", tokenValue);
    if (tokenValue.indexOf("urn:") !== -1) {
      tokenValue = tokenValue.slice(4)
    }
    if (tokenValue.length !== 0 && tokenValue.length === OTP_DEFAULT_LENGTH && !isBadgeScanned) {
      reRegisterAssociate(tokenValue);
    }
  }

  const reRegisterAssociate = (otp: string) => {
    fetch(RE_REGISTER_ASSOCIATE, {
      method: "POST",
      ...REQUEST_INIT,
      headers: {
        ...HEADER_INIT,
        "anti-csrftoken-a2z": sessionDetails.csrfToken,
      },
      body: JSON.stringify({
        otp: otp,
        associateAlias: props.userId,
        serviceName: serviceName,
      }),
    })
      .then((res) => {
        console.log(res);
        if (res.status === 401) {
          // redirect to authenticate
          window.location.replace(currentApiDomain + "/Authorize");
          return res.json();
        }
        if (RECOGNIZED_RESPONSE_STATUS.includes(res.status)) {
          return res.json();
        }
        throw new Error("Unrecognized response status " + JSON.stringify(res));
      })
      .then((res: any) => {
        // Success:200
        console.log(res);
        if (res.hasOwnProperty("responseCode")) {
          if (res.responseCode === 200) {
            console.log(res)
            const responseMessage: ReRegisterAssociateResponse = res;
            // if (responseMessage.success) {
            setState(responseMessage);
            setResponse({
              buttonOnClick: () => navigateToResetPin,
              pageType: ResponsePageType.SUCCESS,
              message: "AA Badge is successfully Re-Registered. Does AA remember their PIN?<br/>On clicking Reset Pin, you will be redirected to Reset PIN page.",
              headerTitle: "Re-Registration successful"
            })
            // setResponsePageOnClick(() => navigateToResetPin);
            // setResponsePageType(ResponsePageType.SUCCESS);
            // setResponseMessage("AA Badge is successfully Re-Registered. Does AA remember their PIN?<br/>On clicking Reset Pin, you will be redirected to Reset PIN page.");
            // setResponseHeaderTitle("Re-Registration successful");
            // }
            return;
          }
          setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
          return;
        }
        // Exception from backend
        if (res.hasOwnProperty("errorId")) {
          res = res as Exception;
          console.error("Unsuccessful API call", res.message, res);
          setErrorCode(res.errorId);
          return;
        }
      })
      .catch((err) => {
        setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
        console.error(err.message);
      });
  };

  // Method to post messages from UI to plugin scripts
  const postMessageToPlugin = (messageObj: any) => {
    window.postMessage({...messageObj, source: "frontend"}, window.location.origin);
  }
  const startScan = () => {
    postMessageToPlugin({type: "startNFCReader"});
  }
  const connectCardReader = () => {
    postMessageToPlugin({type: "checkNFCReader"});
  }

  /****  Hook   *****/
  useEffect(() => {
    console.log("Connecting card reader...")
    connectCardReader();
    window.addEventListener("message", (event) => {
      if (event.origin === window.location.origin && event.data?.source === "plugin") {
        const message = event.data;
        if (message.type === "checkNFCReader") {
          if (message.isReaderAvailable) {
            startScan();
            setShowNFCTapView(true);
          } else {
            if (readerCheckCount < READER_CHECK_LIMIT) {
              setTimeout(function () {
                connectCardReader();
              }, READER_RETRY_IN_MILLISECONDS);
              readerCheckCount++;
            } else {
              setNotification({
                type: NotificationType.ERROR,
                header: 'reader_issue_header',
                message: 'reader_issue_description'
              });
            }
          }
        } else if (message.type === "NFCReaderTimeout") {
          console.log("Check reader request timed out...")
          setShowNFCTapView(false);
          setNotification({
            type: NotificationType.ERROR,
            header: 'reader_issue_header',
            message: 'reader_timeout_description'
          });
        } else if (message.type === "readNDEF") {
          setCardUid(message.otp.slice(5, 19));
          handleToken(message.otp);
        }

      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!sessionDetails?.csrfToken) {
    console.error("Could not find CSRF token, redirecting to home page.");
    return <Navigate to={HOME_PAGE_URL}/>;
  }

  return (
    <div style={{width: "60%", margin: "0 auto"}}>
      {!state && !errorCode && (
        <div>
          <div style={{width: "70%", margin: "0 auto"}}>
            <Notification notificationType={notification.type}
                          headerI18nKey={notification.header}
                          messageI18nKey={notification.message}/>
          </div>
          <NfcTapView visible={showNFCTapView} onDismissModal={onDismissModal}></NfcTapView>
        </div>
      )}

      {errorCode &&
          <div style={{width: "70%", margin: "0 auto"}}>
              <ErrorNotification errorCode={errorCode} data={cardUid}/>
          </div>
      }

      {state && state.responseCode === 200 && response && <ResponsePage
          message={response.message}
          buttonName={"Home"}
          buttonOnClick={() => navigate("/services")}
          buttonName2={"Reset Pin"}
          buttonOnClick2={response.buttonOnClick}
          headerTitle={response.headerTitle}
          pageType={response?.pageType}
      />}
    </div>
  );
}

export default ReRegisterAssociate;