import * as React from 'react';
import {Container, SpaceBetween, Box} from "@amzn/awsui-components-react/polaris";
import {
  GetAssociateDetailsResponse,
  HOME_PAGE_URL,
  SessionDetails
} from "../../../module/AuthAdminModule";
import DisplayAssociateDetails from "../../common/DisplayAssociateDetails";
import Button from "@amzn/awsui-components-react/polaris/button";
import {getTimeZoneAndFormat} from "../../../util/helperUtil";
import {useTranslator} from '../../../localization/Translator';
import {Navigate, NavigateFunction, useLocation, useNavigate, useOutletContext} from 'react-router-dom'
import ReRegisterAssociate from './ReRegisterAssociate';

interface ShowStatusProps {
  data: GetAssociateDetailsResponse
}

// const RE_REGISTER_ASSOCIATE: string = `${currentApiDomain}/ReRegisterAssociate`;

const ShowStatus = (props: ShowStatusProps) => {
  const t = useTranslator();
  const hasUserDetails = !!props.data.userId;

  const [showReRegisterAssociate, setShowReRegisterAssociate] = React.useState(false);

  const sessionDetails: SessionDetails = useOutletContext();

  if (!sessionDetails?.csrfToken) {
    console.error("Could not find CSRF token, redirecting to home page.");
    return <Navigate to={HOME_PAGE_URL}/>;
  }

  const translateBadgeData = (header: string, value: any) => {
    if (value === null) {
      return t.translate(header, {data: "N/A"});
    }
    switch (header) {
      case "badge_details_loaner_badge":
        value = value ? "Yes" : "No";
        break;
      case "badge_details_created_at":
        value = new Date(value).toLocaleString() + getTimeZoneAndFormat(value);
        break;
      case "badge_details_registered_at":
        value = new Date(value).toLocaleString() + getTimeZoneAndFormat(value);
        break;
    }
    // return t.translate(header) + value;
    return t.translate(header, {data: value});
  }

  if (showReRegisterAssociate && props.data.userId) {
    return <ReRegisterAssociate userId={props.data.userId}/>
  }

  return (
    <SpaceBetween direction="vertical" size="m">
      <div style={{display: "flex", gap: 50}}>
        <div style={{flex: 1}}>
          {
            !hasUserDetails &&
              <Container fitHeight
                         header={<h1 style={{textAlign: "center"}}>{t.translate("associate_details_header")}</h1>}>
                {t.translate("unregistered_badge_found_message")}
              </Container>
          }
          {
            hasUserDetails && <DisplayAssociateDetails data={props.data}/>
          }
        </div>
        <div style={{flex: 1}}>
          <Container fitHeight header={<h1 style={{textAlign: "center"}}>{t.translate("badge_details_header")}</h1>}>
            <SpaceBetween direction="vertical" size="s">
              <Box fontSize="body-m"
                   fontWeight="bold">{translateBadgeData("badge_details_status", props.data.employeeBadgeStatus)}</Box>
            </SpaceBetween>
          </Container>
        </div>
      </div>
      <Button variant="primary" onClick={() => setShowReRegisterAssociate(true)}>
        {/*{t.translate("return_to_home_page_button_name")}*/}
        Re Register
      </Button>
    </SpaceBetween>
  );
}

export default ShowStatus;