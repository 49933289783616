import * as React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {
  DEV_OPERATIONS,
  getGroupName, getOrder,
  getServiceNameFromLocation,
  REPORTING_OPERATIONS,
  USER_OPERATIONS
} from '../../util/helperUtil';
import SelectPage from '../common/SelectPage';
import {SpaceBetween} from "@amzn/awsui-components-react";
import DescriptionBox from "../common/DescriptionBox";
import {useTranslator} from "../../localization/Translator";
import GroupSelectPage from '../common/GroupSelectPage';

const Operation = () => {
  const t = useTranslator();
  ;
  const location = useLocation();
  const navigate = useNavigate();
  const operationsString = localStorage.getItem("operations") || JSON.stringify([""]);
  const operations: string[] = JSON.parse(operationsString);
  const serviceName = getServiceNameFromLocation(location);
  const navigateToSite = (operation: string) => {
    navigate(operation);
  }

  const getButtonName = (operation: string) => {
    return t.translate(operation);
  }
  
  const groupOperationsMap: any = {};
  operations.forEach(operation => {
    const groupName = getGroupName(operation);
    if (!groupOperationsMap[groupName]) {
      groupOperationsMap[groupName] = [];
    }
    groupOperationsMap[groupName].push(operation);
  });

  const groups = [];
  for (const groupName in groupOperationsMap) {
    groups.push({
      groupSelectName: groupName,
      selectPages: {
        data: groupOperationsMap[groupName],
        buttonOnClick: navigateToSite,
        buttonNameFn: getButtonName
      },
      order: getOrder(groupName)
    });
  }
  groups.sort((group1, group2) => group1.order - group2.order)
  return (
    <SpaceBetween size={"xs"} alignItems="center">
      <DescriptionBox operations={operations} serviceName={serviceName}/>
      {/*<SelectPage*/}
      {/*  headerTitle={t.translate("operation_component_header", {*/}
      {/*    // To update the service name with localization if needed in the future.*/}
      {/*    serviceName: serviceName.toUpperCase()*/}
      {/*  })}*/}
      {/*  data={operations}*/}
      {/*  buttonOnClick={navigateToSite}*/}
      {/*  buttonNameFn={getButtonName}/>*/}
      <GroupSelectPage
        headerTitle={t.translate("operation_component_header", {
          // To update the service name with localization if needed in the future.
          serviceName: serviceName.toUpperCase()
        })}
        group={groups}
      />
    </SpaceBetween>
  );
}
export default Operation;